
body{
  display:flex;
  flex-direction: column;
}

#root{
  display: contents;
}

@font-face {
  font-family: 'CustomFont'; /* Give your font a name */
  src: url("./data/fonts/edosz.ttf") format('truetype');
}

@font-face {
  font-family: 'riot'; /* Give your font a name */
  src: url("./data/fonts/riotsquad.regular.ttf") format('truetype');
}

.gabriola-font{
  font-family: 'gabriola';
}

.courier-new-font{
  font-family: 'courier new'
}

.custom-font {
  font-family: 'CustomFont';
  font-size: 18px;
}

.riot-font{
  font-family: 'riot';
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

.App-header {
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  padding: 10px;
}

.menu {
  font-family: 'riot';
  font-size:25px;
  border-radius: 0 30px 30px 0;
  /*width: 30vmin;
  min-height: 40vmin;
  */
  background-color: #f8f8f8;
  padding: 1vmin;
  box-sizing: border-box;
  display: flex;
  flex-direction: row-reverse;
  align-content: center;
  flex-wrap: wrap;
  position:fixed;
  z-index: 1;
  top:50%;
}

.left-menu{
  list-style: none;
  margin: 0;
  padding: 0;
  flex: 1;
  overflow-y: hidden;
}

.left-menu li {
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.left-menu li:last-child {
  border-bottom: none;
}

.expand-menu{
  margin-left: 29vmin;
}

.menu-element{
  display: flex;
  /*justify-content: center;*/
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.content{
  position: relative;
  width: 100%;
  /*margin-left: 30vmin;*/
  margin-left: 10vmin;
}

.container{
  display: flex;
}

.book-cover{
  width: 28vmin;
  box-shadow: 0px 10px 5px 0px rgba(0, 0, 0, 0.5);
}

.book-container{
  display:table;
}

.icon{
  width: 4vmin;
  padding:2px;
}

.inline-block{
  display: inline-block;
  font-size: 25px;
}

.hidden-menu-element{
  display:none;
}

.global-bg{
}

.book-title{
  font-size: 20px;
}

.des{
  padding-top: 5vmin;
  font-size: 1.5vmin;
  width: 30vmin;
}

.footer {
  /*position: fixed;*/
  margin-top: 3%;
  bottom: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  background-color: black;
  color: white;
  padding: 25px 0 25px 0;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 40px;
}

.addresse{

}

.reseaux{

}

a{
  color:white;
}

.white{
  filter: invert(100%) sepia(1%) saturate(0%) hue-rotate(188deg) brightness(106%) contrast(101%);
}

.reseau{
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.flex{
  display:flex;
}

.article{
  background-color: white;
  border-radius: 10px;
  border: 1px solid #595959;
  overflow: hidden;

  margin: 10px auto; /* horizontally center */
  display: flex;
  align-items: center; /* vertically center */
  justify-content: center;
  width: 60%;
}

.articles{
  margin-bottom: 25px;
}

.contact-texte{
  position: absolute;
  color: white;
  z-index: 100;
  width: 100%;
  height:100%;
}



#texte_me_contacter{
  font-size: 29px;
}

.invisible{
  display: none;
}

.about{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.lib-img{
  position:relative;
  right: 0;
  width:100%;
}

.horaireTitre{
  color: rgb(175, 68, 67);
  font-size: 35px;
  margin-bottom: 30px;
  padding-top: 10px;
}

.horaireComposant{
  display:table-cell;
}

.horaireConteneur{
  height: 100%;
}

.livre-container{
  display: inline;
  width:100%;
  height: 80%;
  margin: 100px;
}

#table{
  display: table;
  width:100%;
  height: 80%;
  /*margin-top: 20px;*/
}

.tr{
  display: table-row;
}

.td{
  display: table-cell;
  /*text-align: left;*/
  padding: 0px 10px 0px 10px;
  font-size: 20px;
  vertical-align: middle;
}

.center{
  text-align: center;
}

.tdleft{
  text-align: right;

}

.book{
  padding: 0px 15px 30px 15px;
  margin-left: 20px;
  margin-bottom: 20px;
  height: 460px;
  /*display: inline-block;
  border: #909090 1px solid;
  background-color:white;
  border-radius: 10px;*/
  min-width: 30vmin;
  min-height: 51vmin;
  max-height: 51vmin;
}

.inputElement{
  padding: 10px;
  font-size: 15px;
  margin: 10px;
  border-radius: 10px;
  width: 75%;
}

.buttonElement{
  background-color: rgb(175, 67, 68);
  padding:10px;
  border-radius: 10px;
  margin: 10px;
  cursor: pointer;
  display: inline-table;
}

.buttonElement:hover{
  background-color: rgb(94, 6, 6);
}

.messageElement{
  width: 90%;
  height: 400px;
  max-width: 75%;
  min-width: 75%;
  min-height: 300px;
  max-height: 500px;
}

.contactElement{
  background-color: rgba(255, 255, 255, 0.39);
  border-radius: 25px;
  width: 75%;
  margin: auto;
}

.toastContainer{
  z-index: 3;
  position: fixed;
  width: 100%;
}

.toastMessage{
  vertical-align: bottom;
  position: relative;
  top: 25%;
  margin: auto;
  width: 75%;
  background-color: black;
  color:white;
  border-radius: 25px;
  padding: 30px;
}

.toast{
  width: 100%;
  margin:auto;
  height:50px;
  position:fixed;
  margin-top: 15%;
}

.fond{
  background-color: rgba(0, 0, 0, 0.51);
  z-index: 2;
  width: 100%;
  height: 100%;
  scale: 2;
  position: absolute;
}

.none{
  display: none;
}

.inputelement{
  text-align: left;
  vertical-align: middle;
  margin-right: 20px;
}

.flex{
  display: flex;
}

.textarea{
  min-width: 300px;
  max-width: 300px;
}

.articleImage {
  max-height: 500px;
  max-width: 500px;
  width: auto; /* to maintain aspect ratio */
}

.container {
  display: flex;
  align-items: center;
}

.image {
  flex: 1;
  max-height: 500px;
}

.text {
  flex: 1;
  padding: 20px; /* Adjust this value as needed */
}

/* For responsiveness */
@media (max-height: 500px) {
  .container {
    flex-direction: column;
  }

  .text {

  }
}

.afficher{
  background-color: white;
  cursor: pointer;
  padding: 10px;
  font-family: 'riot';
  border-radius: 10px;
  border: 1px solid #595959;
  font-size: 20px;
}

.cursor{
  cursor: pointer;
}